@tailwind base;
@tailwind components;
@tailwind utilities;

$primaryColor: #00a0da;
$primaryColorHover: #0bcde8;

* {
  font-family: 'Roboto', sans-serif;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

main {
  min-height: 100% !important;
  position: relative;
}

.ant-layout-sider {
  .ant-menu-root {
    overflow-x: hidden;
  }
}

.ant-pagination {
  justify-content: center !important;
}

.ant-table-cell:before {
  content: inherit !important;
}

.table {
  width: 100%;

  thead tr .ant-table-cell {
    background: #dddddd;
    font-weight: 400;
  }
}

.yen-input .ant-form-item-control-input {
  position: relative;

  &:before {
    content: '￥';
    font-family: 'Roboto', serif;
    position: absolute;
    bottom: 9px;
    left: 9px;
    z-index: 1;
  }

  input {
    padding-left: 24px;
  }
}

.line-1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.autocomplete-input {
  .ant-select-selector {
    position: relative;

    .ant-select-selection-search {
      padding-right: 16px;
    }

    &:before {
      content: '〱';
      font-family: 'Roboto', serif;
      transform: rotate(-90deg);
      position: absolute;
      top: 9px;
      right: 9px;
      z-index: 1;
    }
  }

  .ant-form-item-control-input {
    //display: none;
  }
}

.text-primary {
  color: $primaryColor;
}

.text-link {
  color: $primaryColor;
}

.bg-primary {
  background: $primaryColor !important;
}

.border-1 {
  border-width: 1px;
}

.ant-btn {
  &.ant-btn-primary {
    background: $primaryColor;

    &:hover {
      background: $primaryColorHover !important;
    }
  }
}

.form-table {
  --border: 1px solid #dddddd;

  border-spacing: 0;
  border-collapse: separate;
  border-radius: 16px;
  border: var(--border);
  overflow: hidden;

  th:not(:last-child),
  td:not(:last-child) {
    border-right: var(--border);
  }

  & > tr:not(:last-child) > td,
  & > tr:not(:last-child) > th {
    border-bottom: var(--border);
  }

  td {
    &.label {
      background: #f8faff;
      min-width: 110px;
      padding: 16px;
    }

    &.input {
      min-width: 310px;
      padding: 9px 16px;
    }
  }
}

:disabled,
.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #00000099 !important;
}

.bg-gradient-primary {
  background: linear-gradient(90deg, #13ae67 0%, #00a0da 95.17%) !important;
}
