.ant-layout-sider-collapsed {
  .ant-menu-title-content {
    display: none;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: inherit;
  }
}

.ant-menu {
  .ant-menu-item,
  .ant-menu-item.ant-menu-item-active,
  .ant-menu-submenu {
    color: white;
    font-weight: 600;
    .ant-menu-sub .ant-menu-title-content {
      font-weight: 500;
      padding-left: 10px;
    }
    .ant-menu-title-content {
      color: white;
    }
    .ant-menu-submenu-arrow {
      &:before,
      &:after {
        color: white;
      }
    }
    .ant-menu-item-icon {
      filter: brightness(0) invert(1);
    }
  }

  .ant-menu-submenu.ant-menu-submenu-selected {
    color: #00a0da;

    .ant-menu-submenu-arrow {
      &:before,
      &:after {
        color: #00a0da;
      }
    }

    .ant-menu-submenu-title {
      background-color: white;

      .ant-menu-item-icon {
        filter: sepia(0.5) saturate(13) hue-rotate(155deg);
      }
      .ant-menu-title-content {
        color: #00a0da;
      }
    }

    .ant-menu-item-selected.ant-menu-item-only-child {
      color: #005e81;
      background-color: rgba(255, 255, 255, 0.85);
    }
  }

  .ant-menu-item.ant-menu-item-selected {
    background-color: white;
    color: #00a0da;
    .ant-menu-title-content {
      color: #00a0da;
    }

    .ant-menu-item-icon {
      filter: sepia(0.5) saturate(13) hue-rotate(155deg);
    }

    &.ant-menu-item-active {
      color: #00a0da;
      .ant-menu-item-icon {
        filter: sepia(0.5) saturate(13) hue-rotate(155deg);
      }
    }

    &:after {
      content: none;
    }
  }
}
